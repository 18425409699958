.swiper {
    padding-top: 50px !important;
}

.swiper-slide {
    transition: transform 0.5s ease;
}

.qwerty.swiper-slide-active {
    /* transform: scaleX(2.3) !important; */
    transform: scale(1.3) !important;
}